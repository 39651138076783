const configMap = {
  development: {
    apiUrl: "https://development-api.dronos.ai",
  },
  testing: {
    apiUrl: "https://testing-api.dronos.ai",
  },
  staging: {
    apiUrl: "https://api-stg.dronos.ai",
  },
  production: {
    apiUrl: "https://amp-tel-api.dronos.ai",
  },
  dev: {
    apiUrl: "https://dev-api.dronos.ai",
  },
  "staging-new": {
    apiUrl: "https://staging-api.dronos.ai",
  },
  "production-new": {
    apiUrl: "https://api.dronos.ai",
  }
};

const currentConfig = configMap[process.env.BUILD_ENV];

module.exports = currentConfig;
